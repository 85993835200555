import React from "react";
import { Modal } from "react-responsive-modal";
import { Download } from "lucide-react";

const Result = ({ onResultPopupToggle, isResultShow, resultImage }) => {
  const imageDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = resultImage;
    downloadLink.download = "my_image.png";
    downloadLink.click();
  };

  return (
    <Modal
      open={isResultShow && resultImage}
      onClose={onResultPopupToggle}
      center
      classNames={{
        modal: "modalContainer",
        modalAnimationIn: "customEnterModalAnimation",
        modalAnimationOut: "customLeaveModalAnimation",
        closeIcon: "closeIcon",
      }}
      animationDuration={1000}
    >
      <div onClick={imageDownload} className="download-btn">
        <Download />
      </div>
      <div className="result-image-container">
        <img
          onLoad={() => {
            console.log("Image Loaded");
          }}
          alt="result"
          src={resultImage}
        />
      </div>
    </Modal>
  );
};

export default Result;
