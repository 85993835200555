import React from "react";
import Dropzone from "react-dropzone";
import { Upload } from "lucide-react";

const FileDropZone = ({ onFileChange }) => {
  return (
    <Dropzone
      accept={{
        image: [".png", ".jpg", ".jpeg"],
      }}
      onDrop={(acceptedFiles) => {
        if (acceptedFiles?.length > 0) {
          onFileChange(acceptedFiles[0]);
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section id="drop-section" className="file-drop-zone">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="file-drop-zone-text">
              <Upload style={{ color: "#bdbdbd" }} /> <p>Drop Your File Here</p>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDropZone;
