import React, { useState } from "react";
import Image1 from "../assets/img/image_1.png";
import Image2 from "../assets/img/image_2.png";
import Image3 from "../assets/img/image_3.png";

const Carousel = () => {
  const [slideId, setSlideId] = useState(0);

  const changeSlide = (_id) => {
    setSlideId(_id);
  };

  return (
    <div className="inner-right-box">
      <div>
        {slideId === 0 && <img src={Image1} class={`image`} alt="" />}
        {slideId === 1 && <img src={Image2} class={`image`} alt="" />}
        {slideId === 2 && <img src={Image3} class={`image`} alt="" />}
      </div>

      <div>
        <div className="text-wrap">
          {slideId === 0 && (
            <h2 className="text">Spot Faces, Age, Gender & Emotions</h2>
          )}
          {slideId === 1 && (
            <h2 className="text">Discover Objects - Identify What's Around</h2>
          )}
          {slideId === 2 && (
            <h2 className="text">Find Celebrities - Spot the Stars</h2>
          )}
        </div>

        <div class="bullets">
          <span
            onClick={() => changeSlide(0)}
            class={`${slideId === 0 && "active"}`}
          ></span>
          <span
            onClick={() => changeSlide(1)}
            class={`${slideId === 1 && "active"}`}
          ></span>
          <span
            onClick={() => changeSlide(2)}
            class={`${slideId === 2 && "active"}`}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
