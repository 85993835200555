import "./assets/styles/style.css";
import "react-responsive-modal/styles.css";
import Logo from "./assets/img/react-ai.png";
import FileDropZone from "./components/dropZone";
import Carousel from "./components/imageSlider";
import Result from "./components/result";
import { useState } from "react";
import axios from "axios";

const baseURL = "https://python-api.techsimplus.com/api/amazon-service/";

function App() {
  const [isResultShow, setIsResultShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [noResultFoundError, setNoResultFoundError] = useState(null);
  const [resultImage, setResultImage] = useState("");
  const [values, setValues] = useState({
    image: null,
    service_type: "FaceDetection",
  });

  const onResultPopupToggle = () => {
    setIsResultShow(!isResultShow);
  };

  const onFileChange = (file) => {
    setValues({ ...values, image: file });
    setImageError(false);
  };

  const onRadioButtonHandler = (value) => {
    setValues({ ...values, service_type: value });
  };

  function fileToBase64(file, callback) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.readAsDataURL(file);
    });
  }

  const onSubmitHandler = async () => {
    if (!values.image) {
      setImageError(true);
      return;
    }

    let base64Image = await fileToBase64(values.image);
    let newValues = { ...values, image: base64Image };
    setIsLoading(true);
    try {
      let response = await axios.post(baseURL, newValues);
      if (response?.data?.isNotFound) {
        setNoResultFoundError("No Result Found");
      } else if (response?.data?.base64Image) {
        let base64Image = "data:image/png;base64, " + response.data.base64Image;
        setResultImage(base64Image);
        setIsResultShow(true);
      }
    } catch (error) {
      setNoResultFoundError("Internal Server Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div class="container">
      {isResultShow && (
        <Result
          resultImage={resultImage}
          isResultShow={isResultShow}
          onResultPopupToggle={onResultPopupToggle}
        />
      )}

      {isLoading && (
        <div class="loading-overlay">
          <div class="loader"></div>
        </div>
      )}
      <div class="box">
        <div className="left-box">
          <div className="inner-left-box">
            <div class="logo">
              <img src={Logo} alt="Logo" />
              <h4>AI-React</h4>
            </div>

            <div class="heading">
              <h2>AI-Powered Fun with React</h2>
              <h6>Let's Play Together!</h6>
            </div>

            {imageError && (
              <span style={{ color: "darkred" }}>Image Required</span>
            )}

            {values?.image && (
              <span style={{ color: "darkblue" }}>
                File: {values?.image?.name}
              </span>
            )}
            <FileDropZone onFileChange={onFileChange} />

            <div className="service-container">
              <div className="service-item">
                <input
                  onChange={() => {
                    onRadioButtonHandler("ObjectDetection");
                  }}
                  checked={values.service_type === "ObjectDetection"}
                  id="object-detection"
                  name="service-type"
                  type="radio"
                />
                <label htmlFor="object-detection">Object Detection</label>
              </div>
              <div className="service-item">
                <input
                  onChange={() => {
                    onRadioButtonHandler("FaceDetection");
                  }}
                  checked={values.service_type === "FaceDetection"}
                  id="face-detection"
                  name="service-type"
                  type="radio"
                />
                <label htmlFor="face-detection">Face Detection</label>
              </div>
              <div className="service-item">
                <input
                  onChange={() => {
                    onRadioButtonHandler("CelebrityDetection");
                  }}
                  checked={values.service_type === "CelebrityDetection"}
                  id="celebrity-detection"
                  name="service-type"
                  type="radio"
                />
                <label htmlFor="celebrity-detection">Celebrity Detection</label>
              </div>
            </div>

            <button onClick={onSubmitHandler} class="sign-btn">
              See The Magic
            </button>

            {noResultFoundError && (
              <div className="no-result">
                <p>{noResultFoundError}</p>
              </div>
            )}
          </div>
        </div>
        <div className="right-box">
          <Carousel />
        </div>
      </div>
    </div>
  );
}

export default App;
